import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";


const Navbar= function() {
  const [showmenu, btn_icon] = useState(false);
  useEffect(() => {
    const header = document.getElementById("header-wrap");
    const flytext = document.getElementById("fly");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
          totop.classList.add("show");
          flytext.classList.add("hide");
        } else {
          header.classList.remove("sticky");
          flytext.classList.remove("hide");
          totop.classList.remove("show");
        } 
      });
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    }, []);
    return(
        <nav className="navbar transition rev">
        <div className="container">
          <button className="btn-main">
            <Link className="nav-link transition" spy to="contact">
                Get in Touch
            </Link>
          </button>
          <Link  className="navbar-brand" activeClass="active" spy to="hero-area">
            <img src="./img/logo/logo.png" className="img-fluid imgsaly" alt="logo" height="42" width="170" />
            <img src="./img/logo/logo.png" className="img-fluid imgdark" alt="logo" height="42" width="170" />
          </Link>

          {/* Desktop menu Here */}
          <div className={showmenu ? "mainreveal show" : "mainreveal"}>
            {showmenu && 
             <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="hero-area" onClick={() => btn_icon(!showmenu)}>
                  H o m e
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="about" onClick={() => btn_icon(!showmenu)}>
                  A b o u t &bull; U s
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="whatwedo" onClick={() => btn_icon(!showmenu)}>
                  W h a t &bull; W e &bull; D o
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="portfolio" onClick={() => btn_icon(!showmenu)}>
                  P o r t f o l i o
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="projects" onClick={() => btn_icon(!showmenu)}>
                  P r o j e c t s
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="news" onClick={() => btn_icon(!showmenu)}>
                  N e w s
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link transition" activeClass="active" spy to="contact" onClick={() => btn_icon(!showmenu)}>
                  C o n t a c t
                </Link>
              </li>
            </ul>
            }
          </div>
          
          {/* Desktop menu Here */}
          <span id="de-menu-reveal" className={showmenu ? "menu-open" : ""} onClick={() => btn_icon(!showmenu)}>
              <span className="lt"></span>
              <span className="lm"></span>
              <span className="lb"></span>
          </span>
        </div>
      </nav>
    )
}

export default Navbar;