import React from 'react';
import Skillbar from '../layout/SkillBar';
import AOS from 'aos';

AOS.init();

const AboutDark = () => {
	return (
		<div className="v-center">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-md-6">
						<img src="./img/misc/4.png" className="img-fluid" alt="" />
					</div>
					<div className="col-md-6">
						<h2>About Us</h2>
						<p>
						Lantern light is a creative marketing agency making decisions based on data. 
						Using Meta’s cookies and analytics we track who and where your customers are. This technological edge allows us to maximize return on ad spend with precise targeting of potential customers.
						</p>
						{/* <div className="d-skills-bar">
							<div className="d-bar">
								<div className="d-skill">
									<div className="d-info">
										<span>Branding</span>
									</div>
									<Skillbar bgColor={"#50BEC2"} progress={90} />
								</div>

								<div className="d-skill">
									<div className="d-info">
										<span>Content</span>
									</div>
									<Skillbar bgColor={"#50BEC2"} progress={70} />
								</div>

								<div className="d-skill">
									<div className="d-info">
										<span>Marketing</span>
									</div>
									<Skillbar bgColor={"#50BEC2"} progress={82} />
								</div>

							</div>
						</div> */}
					</div>

				</div>
			</div>
		</div>
	);
}

export default AboutDark;