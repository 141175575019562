import React from 'react';
import { Link } from "react-scroll";
import AOS from 'aos';

AOS.init({
    offset: 200,
    duration: 800,
    easing: 'ease-in-out-sine',
    delay: 200,
    mirror: true
});

const HeroDark = () => {
    return (
        <div className="v-center">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h6 className="color"
                            data-aos="fade-up"
                            data-aos-delay="0"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                        >Lantern Light Marketing
                        </h6>
                        <div className="spacer-20"></div>
                        <div className="h1_big mb-0">
                            Digital Marketing
                            <span className="color"> Driven by Data</span>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <img src="./img/misc/8.png" className="img-fluid" alt="Lantern Light Marketing" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeroDark;