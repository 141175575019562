import React from 'react';
import AOS from 'aos';

AOS.init();

const Projects = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2>Projects</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <div className="row gh-5">

                <div className="col-lg-6"
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="p-4">
                        {/* <h3 className="s_border">Experiences</h3> */}
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2014 - now</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Founder &amp; Creative Director</span>
                                    <span className="d_company">Kyros Studio</span>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi tempora perferendis vero officia enim impedit voluptatem dignissimos, veniam ratione est alias rerum aperiam, nam aliquam reprehenderit iste dolor.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2010 - 2014</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Senior UI / UX Designer</span>
                                    <span className="d_company">Google Inc</span>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi tempora perferendis vero officia enim impedit voluptatem dignissimos, veniam ratione est alias rerum aperiam, nam aliquam reprehenderit iste dolor.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2008 - 2010</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Graphic Designer</span>
                                    <span className="d_company">Kyros Studio</span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi tempora perferendis vero officia enim impedit voluptatem dignissimos, veniam ratione est alias rerum aperiam, nam aliquam reprehenderit iste dolor.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-6"
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="p-4">
                        {/* <h3 className="s_border">Education</h3> */}
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2004 - 2008</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Master in Design</span>
                                    <span className="d_company">New York University</span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi tempora perferendis vero officia enim impedit voluptatem dignissimos, veniam ratione est alias rerum aperiam, nam aliquam reprehenderit iste dolor.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2004 - 2008</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Bachelor of Arts</span>
                                    <span className="d_company">University of London</span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi tempora perferendis vero officia enim impedit voluptatem dignissimos, veniam ratione est alias rerum aperiam, nam aliquam reprehenderit iste dolor.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2006 - 2004</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Artist of College</span>
                                    <span className="d_company">University of Sydney</span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi tempora perferendis vero officia enim impedit voluptatem dignissimos, veniam ratione est alias rerum aperiam, nam aliquam reprehenderit iste dolor.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="spacer-double"></div>
            </div>
        </div>
    );
}

export default Projects;