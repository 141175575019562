import { Parallax } from "react-parallax";
import { Link } from "react-scroll";
import Navbar from '../layout/NavBar';
import Preloader from "../layout/PreLoader";
import ScrollToTopBtn from '../layout/ScrollToTop';
import About from '../section/AboutDark';
import BlockQuote from '../section/BlockQuoteDark';
import News from '../section/News';
import Contact from '../section/ContactDark';
// import Counter from '../section/CounterDark';
import Footer from '../section/Footer';
import Hero from '../section/HeroDark';
import Portfolio from '../section/Portfolio';
import Projects from '../section/Projects';
// import Team from '../section/Team';
import WhatWeDo from '../section/WhatWeDo';
import { GlobalStyles } from './style/Dark';

const image1 = "./img/background/cup.jpg";

function home() {
  return (
    <>
      <GlobalStyles />
      <Preloader />

      <div className="home">
        <header id="header-wrap">
          <Navbar />
        </header>

        <section id="hero-area" className="bg-bottom py-0">
          <Parallax bgImage={image1} strength={300}>
            <Hero />
            <Link smooth spy to="about">
              <span className="mouse transition" id="fly">
                <span className="scroll"></span>
              </span>
            </Link>
          </Parallax>
        </section>

        <section id="about" className="pb-0">
          <About />
        </section>

        <section id="whatwedo" className="pb-0">
          <WhatWeDo />
          <BlockQuote />
        </section>

        {/* <section id="portfolio">
          <Portfolio />
        </section> */}

        {/* <section id="projects" className="pb-0">
          <Projects />
        </section> */}

        {/* <section id="news" className="pb-0">
          <News />
        </section> */}

        <section id="contact" className="py-0">
          <Contact />
          <Footer />
        </section>

        <div className="float-text">
          <div className="de_social-icons">
            <span className="buton"><i className="fa fa-facebook fa-lg"></i></span>
            <span className="buton"><i className="fa fa-twitter fa-lg"></i></span>
            <span className="buton"><i className="fa fa-instagram fa-lg"></i></span>
          </div>
          {/* <span>Follow Us</span> */}
        </div>
      </div>

      <ScrollToTopBtn />
    </>
  );
}

export default home;
